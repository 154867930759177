import ApiService from "@/service/api.service";
export default {
  computed: {
    refTitleQuestion: {
      get() {
        return this.$store.state.contentTestBuilderStore.refTitleQuestion;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setRefTitleQuestion", value);
      },
    },
  },
  methods: {
    async getPinyinFromChineseTextSelectedToRefInputEditor(inputRef = '') {
      if (!inputRef) {
        this.messError('Có lỗi ! Hãy báo bên kỹ thuật');
        return;
      }
      let refTitleQuestion = this.$refs[inputRef].$refs.editorCustom;
      let editor = refTitleQuestion.$_instance;

      let textSelected = this.getTextSelectedByEditor(editor);
      if (!textSelected) {
        this.messError('Chưa chọn vùng text để lấy pinyin');
        return;
      }

      let dataPinyin = await this.getDataPinyinByTextSelected(textSelected);
      if (!dataPinyin) return;

      editor.model.change( writer => {
        const range = editor.model.document.selection.getFirstRange();
        editor.model.insertContent(writer.createText(dataPinyin) , range )
      } );

      editor.editing.view.focus();
    },

    getTextSelectedByEditor(editor) {
      let selection = editor.model.document.selection;
      let range = selection.getFirstRange();

      let result = '';

      for (const item of range.getItems()) {
        result += item.data;
      }
      return result;
    },

    async getDataPinyinByTextSelected(textSelected = '') {
      let vm = this;
      try {
        vm.is_call_api = true;
        let data = {
          sentence: textSelected,
        }
        let res = await ApiService.post("prep-app/test/draft/question/get-phonetic-sentence", data);
        if (res.status === 200) {
          vm.is_call_api = false;
          return res.data;
        }
      } catch (error) {
        vm.is_call_api = false;
        if (error.response.status === 422 || error.response.status === 409) {
          vm.messError(error.response.data.error.message);
        } else if(error) {
          vm.$toasted.error("Something Wrong. Please try again or contact admin !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
        }
        return '';
      }
    },

    messError(msg = '') {
      this.$toasted.error(msg, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },

    decodeHTMLForChineseCharacter(stringChinese = '') {
      if (!stringChinese) {
        return '';
      }
      const replacements = [
        ["&lt;ruby&gt;", "<ruby>"],
        ["&lt;/ruby&gt;", "</ruby>"],
        ["&lt;rt&gt;", "<rt>"],
        ["&lt;/rt&gt;", "</rt>"]
      ];

      let decodedString = stringChinese;
      replacements.forEach(([encoded, original]) => {
        decodedString = decodedString.replaceAll(encoded, original);
      });

      return decodedString;
    },
    encodeHTMLForChineseCharacter(stringChinese = '') {
      if (!stringChinese) {
        return '';
      }
      const replacements = [
        ["<ruby>", "&lt;ruby&gt;"],
        ["</ruby>", "&lt;/ruby&gt;"],
        ["<rt>", "&lt;rt&gt;"],
        ["</rt>", "&lt;/rt&gt;"]
      ];

      let encodedString = stringChinese;
      replacements.forEach(([original, encoded]) => {
        encodedString = encodedString.replaceAll(original, encoded);
      });

      return encodedString;
    },
  },
  filters: {}
}